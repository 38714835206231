import type { ListingStage, StageCount } from '@/api/v4/project-listings.api';

export const getStageListingsCount = (
  stage: ListingStage,
  stagesCount: StageCount[],
): number => {
  return (
    stagesCount.find(stageCount => stageCount.stageName === stage.name)
      ?.count || 0
  );
};
