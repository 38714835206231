import type { Project } from '@/api/v4/projects.api';

export const getEditFormDefaultValues = (project?: Project | null) => {
  return {
    name: project?.name ?? null,
    timeframe:
      project?.timeframeStart && project?.timeframeEnd
        ? {
            timeframeStart: project.timeframeStart,
            timeframeEnd: project.timeframeEnd,
          }
        : null,
    status: project?.status ?? null,
    owner: project?.owner
      ? {
          value: String(project.owner.id),
          label: `${project.owner.firstName} ${project.owner.lastName}`,
        }
      : null,
    createdFor: project?.team
      ? { value: project.team.id, label: project.team.name }
      : null,
    type: project?.type
      ? { value: project.type.id, label: project.type.name }
      : null,
  };
};
