import { useRecoilValue } from 'recoil';

import type { ListingStage } from '@/api/v4/project-listings.api';

import { projectIdState } from '../../project.state';
import { useProjectListings } from '../use-project-listings.query';
import { useDealStatuses } from './use-deal-statuses';
import { ListingStageName } from '@/features/listing-stage/types';

export const useTabProjectListings = (stageTab: ListingStage) => {
  const projectId = useRecoilValue(projectIdState);
  const dealStatuses = useDealStatuses();

  const getStageFilterParam = (stage: ListingStage): string[] => {
    if (stage.name === ListingStageName.deal) {
      return dealStatuses.map(status => status.id);
    } else {
      return [stage.id];
    }
  };

  const { projectListings, hasNextPage, fetchNextPage } = useProjectListings({
    projectId,
    stageFilter: getStageFilterParam(stageTab),
  });

  return { data: projectListings, hasNextPage, fetchNextPage };
};
