import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { projectListingSortState } from '../../project-listing.state';
import type { ISort } from '@/ui/table/table.types';
import { useProjectListings } from '../use-project-listings.query';
import { projectIdState } from '../../project.state';
import type {
  ProjectCompaniesSort,
  ProjectListing,
} from '@/api/v4/projects.api';
import { orderBy } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@/config/query-client';

const sortKeysHandledByFrontend: Array<Partial<ProjectCompaniesSort>> = [
  'companyName',
  'yearEstablished',
  'capitalRaised',
  'averageRating',
];

export const useSortStageListings = () => {
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);
  const { projectListings } = useProjectListings({ projectId });
  const [sortData, setSortData] = useRecoilState(projectListingSortState);
  const resetProjectListingSort = useResetRecoilState(projectListingSortState);

  const getOrganizationKey = (sortKey: ProjectCompaniesSort) => {
    if (sortKey === 'companyName') {
      return 'name';
    }

    return sortKey;
  };

  const handleStageContentSort = ({
    sort,
    direction,
  }: ISort<ProjectCompaniesSort>) => {
    if (sortKeysHandledByFrontend.includes(sort)) {
      const sortedData = orderBy(
        projectListings,
        listing =>
          listing.organization[
            getOrganizationKey(sort) as keyof ProjectListing['organization']
          ],
        direction,
      );

      queryClient.setQueryData<ProjectListing[]>(
        [QueryKey.ProjectListings, projectId, { sort, direction }],
        sortedData,
      );
    }

    return setSortData({ sort, direction });
  };

  return {
    resetProjectListingSort,
    sortData,
    handleStageContentSort,
  };
};
