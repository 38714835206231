import { styled } from 'goober';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { openNewTab } from '@/helpers/open-new-tab';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Checkbox } from '@/ui/checkbox/checkbox';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { notify } from '@/ui/snackbar/notify';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import type { IHeaderCell, Row } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { selectedCompaniesState } from '../../companies/companies.state';
import { CompanyDescriptionLoader } from '../../modals/enhance-project-modals/results/company-description-loader';
import type { ExploreResult } from '../explore.state';
import {
  exploreResultsCreatedCompaniesState,
  exploreResultsCreatingCompaniesState,
  exploreResultsSelectedCompaniesState,
} from '../explore.state';

import type { CompanySortOptions } from '@/api/v4/organization.api';
import { LogoIcon } from '../../navbar/navbar.styles';
import { ExploreCreateCompanyActionButton } from './explore-create-company-action-button';
import { useGenAIRequestResultMutation } from './use-genai-request-result-mutation';
import { ExploreResultsCompanyItemBaseInfo } from './cells/explore-results-company-item-base-info';
import {
  CompanyDataRenderer,
  CompanyDataRow,
  CurrencyValue,
  formatYearOnly,
} from './cells/company-data-renderer';
import { format } from 'date-fns';
import { getAbbreviateNumber } from '@/helpers/format-number';

type useExploreResultsTableConfigProps = {
  requestId: string;
  suggestions: ExploreResult[];
  dataQueryKey?: string;
};

export const useExploreResultsTableConfig = ({
  requestId,
  suggestions,
  dataQueryKey,
}: useExploreResultsTableConfigProps) => {
  const { t } = useTranslation('explore');
  const [newCreatedCount, setNewCreatedCount] = useState(0);
  const { mutateAsync: updateGenAIResultStatus } =
    useGenAIRequestResultMutation();
  const setAddToProjectCompanies = useSetRecoilState(selectedCompaniesState);
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    exploreResultsSelectedCompaniesState,
  );
  const [creatingCompanies, setCreatingCompanies] = useRecoilState(
    exploreResultsCreatingCompaniesState,
  );
  const [createdCompanies, setCreatedCompanies] = useRecoilState(
    exploreResultsCreatedCompaniesState,
  );

  useEffect(() => {
    setAddToProjectCompanies([]);
    setSelectedCompanies([]);
    setCreatingCompanies([]);
    setCreatedCompanies([]);
  }, []);

  useEffect(() => {
    if (newCreatedCount && !creatingCompanies.length) {
      notify({ message: t('companiesCreated', { count: newCreatedCount }) });
      setNewCreatedCount(0);
    }
  }, [newCreatedCount, creatingCompanies, t]);

  const { createCompanyFromCrunchbase } = useCreateCompany();

  const isCreating = useCallback(
    (crunchbaseId?: string | null) => {
      if (!crunchbaseId) return false;
      return creatingCompanies.some(item => item.crunchbaseId === crunchbaseId);
    },
    [creatingCompanies],
  );

  const isCreated = useCallback(
    (crunchbaseId?: string | null) => {
      if (!crunchbaseId) return false;
      return createdCompanies.some(item => item.crunchbaseId === crunchbaseId);
    },
    [createdCompanies],
  );

  const createSavvyCompany = useCallback(
    async (item: ExploreResult) => {
      if (
        !item.crunchbaseId ||
        item.organizationId ||
        isCreated(item.crunchbaseId) ||
        isCreating(item.crunchbaseId)
      )
        return;
      setNewCreatedCount(count => count + 1);
      setCreatingCompanies(companies => [...companies, item]);
      try {
        const organization = await createCompanyFromCrunchbase({
          cbId: item.crunchbaseId,
          source: 'Savvy - OpenAI - Explore',
        });
        setCreatingCompanies(companies =>
          companies.filter(c => c.crunchbaseId !== item.crunchbaseId),
        );
        setCreatedCompanies(companies => [
          ...companies,
          {
            ...item,
            organizationId: organization.id,
          },
        ]);
        await updateGenAIResultStatus({
          params: { requestId, resultNumber: item.resultNumber.toString() },
          body: { newStatus: 1 },
        });
        return organization;
      } catch {
        notify(t`unknownError`);
        return;
      }
    },
    [
      createCompanyFromCrunchbase,
      isCreated,
      isCreating,
      requestId,
      setCreatedCompanies,
      setCreatingCompanies,
      t,
      updateGenAIResultStatus,
    ],
  );

  const CompanyActions = () => {
    const { t } = useTranslation('companies');
    const setModalState = useSetRecoilState(modalState);
    const { hasRequiredPermission } = useUserPermissions();
    const isActionsEnabled = creatingCompanies.length === 0;
    const selectedAlreadyInSavvy = selectedCompanies.filter(
      item => item.organizationId,
    );
    const selectedNoCrunchbaseId = selectedCompanies.filter(
      item => !item.crunchbaseId,
    );

    return (
      <>
        {hasRequiredPermission(PERMISSION.ADD_COMPANY_TO_STREAM) && (
          <Button
            variant="white"
            startIcon="Plus"
            isLoading={!isActionsEnabled}
            onClick={async e => {
              e.stopPropagation();
              if (selectedAlreadyInSavvy.length) {
                selectedAlreadyInSavvy.forEach(item => {
                  notify({
                    message: t(
                      'companies.checkboxActions.addToSavvySelectionAlreadyinSavvyError',
                      { companyName: item.name },
                    ),
                  });
                });
              }
              if (selectedNoCrunchbaseId.length) {
                selectedNoCrunchbaseId.forEach(item => {
                  notify({
                    message: t(
                      'companies.checkboxActions.addToSavvySelectionNoCBError',
                      { companyName: item.name },
                    ),
                  });
                });
              }
              if (!isActionsEnabled) return;
              await Promise.all(
                selectedCompanies.map(
                  async item => await createSavvyCompany(item),
                ),
              );
            }}
          >
            {t`companies.checkboxActions.addToSavvy`}
          </Button>
        )}

        {hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) && (
          <AddToProjectButton
            variant="white"
            startIcon="Plus"
            isLoading={!isActionsEnabled}
            onClick={e => {
              e.stopPropagation();
              if (!isActionsEnabled) return;
              setAddToProjectCompanies(
                selectedCompanies.map(item => {
                  const newSavvyId = createdCompanies.find(
                    company => company.crunchbaseId === item.crunchbaseId,
                  )?.organizationId;

                  return {
                    crunchbaseId: item.crunchbaseId || '',
                    organizationId:
                      newSavvyId || item.organizationId || undefined,
                  };
                }),
              );
              setModalState({ state: 'addCompanyToProjects' });
            }}
          >
            {t`companies.checkboxActions.addToProject`}
          </AddToProjectButton>
        )}
      </>
    );
  };

  const headerConfig: IHeaderCell<CompanySortOptions | null>[] = [
    {
      columnKey: 'companiesHeaderWithCount',
      count: suggestions.length,
      value: 'company',
      height: '10px',
      colSpan: 1,
      sortKey: 'name',
      padding: '30px 20px 20px 60px',
      showCheckbox: true,
      defaultSortDirection: 'desc',
      isChecked: Boolean(selectedCompanies.length),
      checkboxCount: selectedCompanies.length,
      checkboxIcon:
        selectedCompanies.length !== suggestions.length
          ? 'CheckMarkMinus'
          : undefined,
      checkboxActions:
        selectedCompanies.length > 0 ? <CompanyActions /> : undefined,
      onCheckboxChange: isChecked => {
        if (!isChecked) return setSelectedCompanies([]);
        setSelectedCompanies(suggestions);
      },
    },
    {
      value: 'keyOffering',
      columnKey: 'keyOffering',
    },
    {
      value: 'sourceKey',
      columnKey: 'sourceKey',
      align: 'center',
    },
    {
      value: 'funded',
      columnKey: 'funded',
      align: 'center',
    },
    {
      value: 'fundingStage',
      columnKey: 'fundingStage',
      align: 'center',
    },
    {
      value: 'capitalRaised',
      columnKey: 'capitalRaised',
      align: 'center',
    },
    {
      value: 'lastModified',
      columnKey: 'lastModified',
      align: 'center',
    },
    {
      value: 'action',
      columnKey: t`action`,
      hideLabel: true,
    },
  ];

  const headers = useTranslateHeaders(headerConfig, 'companies', 'companies');

  const handleOnRowCheckboxChange = useCallback(
    (target: ExploreResult) => {
      const isSelected = selectedCompanies.some(
        item =>
          item.crunchbaseId === target.crunchbaseId &&
          item.name === target.name,
      );
      if (isSelected) {
        // deselect
        setSelectedCompanies(data =>
          data.filter(
            item =>
              !(
                item.crunchbaseId === target.crunchbaseId &&
                item.name === target.name
              ),
          ),
        );
      } else {
        setSelectedCompanies(data => [...data, target]);
      }
    },
    [selectedCompanies, setSelectedCompanies],
  );

  const renderSourceComponent = useCallback(
    (source: ExploreResult['source']) => {
      switch (source) {
        case 'Open Web':
          return <SavvyAISource>{t`source.openWeb`}</SavvyAISource>;
        case 'Savvy Account':
          return (
            <Tooltip content={t`source.savvyAccountTooltip`}>
              <SourceBadgeContainer name={'ExistInAccount'} padding="5px 12px">
                <SourceExistInAccountText>
                  {t`source.savvyAccount`}
                </SourceExistInAccountText>
              </SourceBadgeContainer>
            </Tooltip>
          );
        case 'SOSA DB':
          return (
            <Tooltip content={t`source.verifiedAccountTooltip`}>
              <SourceBadgeContainer name={'SavvyVerifiedDB'} padding="8px 12px">
                <LogoIcon height="20px" width="20px" />
                <SourceSavvyText>{t`source.verifiedAccount`}</SourceSavvyText>
              </SourceBadgeContainer>
            </Tooltip>
          );
      }
    },
    [t],
  );

  const rows: Row[] = useMemo<Row[]>(
    () =>
      suggestions.map(suggestion => {
        const hasProfile =
          !!suggestion.organizationId && suggestion.source !== 'SOSA DB';

        const shouldFetchData = Boolean(!hasProfile && suggestion.crunchbaseId);

        const isSelected = selectedCompanies.some(
          item =>
            item.crunchbaseId === suggestion.crunchbaseId &&
            item.name === suggestion.name,
        );
        const savvyPath = suggestion.organizationId
          ? paths.company({
              companyId: suggestion.organizationId,
            })
          : '';

        return {
          id:
            suggestion.organizationId ??
            suggestion.crunchbaseId ??
            suggestion.name ??
            '',
          rowPadding: {
            left: '60px',
            right: '40px',
          },
          rowHover: true,
          rowHeight: '61px',
          cells: [
            {
              width: '15%',
              value: (
                <Flexbox
                  name="explore-company-name"
                  gap="8px"
                  alignItems="center"
                  fullWidth
                >
                  <RowCheckbox
                    id={
                      suggestion.crunchbaseId ||
                      suggestion.resultNumber.toString()
                    }
                    label=""
                    checked={isSelected}
                    onChange={() => handleOnRowCheckboxChange(suggestion)}
                  />
                  <ExploreResultsCompanyItemBaseInfo data={suggestion} />
                </Flexbox>
              ),
            },
            {
              align: 'left',
              width: '28%',
              value: (
                <CompanyDescriptionLoader
                  key={suggestion.name}
                  requestId={requestId}
                  resultNumber={suggestion.resultNumber}
                  companyName={suggestion.name ?? ''}
                  companyWebsite={suggestion.website}
                  shortDescription={suggestion.shortDescription}
                  dataQueryKeyToSort={dataQueryKey}
                />
              ),
            },
            {
              align: 'center',
              width: '12%',
              value: renderSourceComponent(suggestion.source),
            },
            {
              align: 'center',
              width: '8%',
              value:
                shouldFetchData && suggestion.crunchbaseId ? (
                  <CompanyDataRenderer
                    crunchbaseId={suggestion.crunchbaseId}
                    field="lastFundingDate"
                    fallback="--"
                  />
                ) : (
                  <CompanyDataRow>
                    {suggestion.lastFundingDate
                      ? formatYearOnly(suggestion.lastFundingDate)
                      : '--'}
                  </CompanyDataRow>
                ),
            },
            {
              align: 'center',
              width: '8%',
              value:
                shouldFetchData && suggestion.crunchbaseId ? (
                  <CompanyDataRenderer
                    crunchbaseId={suggestion.crunchbaseId}
                    field="lastFundingType"
                    fallback="--"
                  />
                ) : (
                  <CompanyDataRow>
                    {suggestion.fundingStage ?? '--'}
                  </CompanyDataRow>
                ),
            },
            {
              align: 'center',
              width: '8%',
              value:
                shouldFetchData && suggestion.crunchbaseId ? (
                  <CompanyDataRenderer
                    crunchbaseId={suggestion.crunchbaseId}
                    field="fundingTotal"
                    fallback="--"
                  />
                ) : (
                  <CompanyDataRow>
                    {suggestion.capitalRaised ? (
                      <CurrencyValue>
                        $
                        {getAbbreviateNumber(
                          suggestion.capitalRaised as unknown as string,
                        )}
                      </CurrencyValue>
                    ) : (
                      '--'
                    )}
                  </CompanyDataRow>
                ),
            },
            {
              align: 'center',
              width: '8%',
              value: (
                <CompanyDataRow>
                  {suggestion.lastEditedAt
                    ? format(new Date(suggestion.lastEditedAt), 'dd MMM yyyy')
                    : '--'}
                </CompanyDataRow>
              ),
            },
            {
              hoverOnly: hasProfile || !isCreating(suggestion.crunchbaseId),
              align: 'center',
              width: '5%',
              value: (
                <Flexbox name="explore-result-action" justify="flex-end">
                  {hasProfile ? (
                    <Button
                      onClick={() => {
                        openNewTab(savvyPath);
                      }}
                      endIcon="VectorUp"
                    >{t`profile`}</Button>
                  ) : (
                    <ExploreCreateCompanyActionButton
                      isCreated={isCreated(suggestion.crunchbaseId)}
                      isCreating={isCreating(suggestion.crunchbaseId)}
                      onClick={createSavvyCompany}
                      requestId={requestId}
                      suggestion={suggestion}
                    />
                  )}
                </Flexbox>
              ),
            },
          ],
        };
      }),
    [
      suggestions,
      selectedCompanies,
      requestId,
      dataQueryKey,
      renderSourceComponent,
      isCreating,
      t,
      isCreated,
      createSavvyCompany,
      handleOnRowCheckboxChange,
    ],
  );

  return { rows, headers };
};

const RowCheckbox = styled(Checkbox)`
  & + div {
    margin-right: 16px;
  }
`;

const AddToProjectButton = styled(Button)`
  color: ${({ theme }) => theme.colors.blue.primaryA};
  &:hover {
    color: ${({ theme }) => theme.colors.blue.primaryA};
  }
`;

const SavvyAISource = styled('label')`
  color: ${({ theme }) => theme.colors.gray.c12};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.36px;
`;

const SourceBadgeContainer = styled(Flexbox)<{ padding?: string }>`
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: ${({ padding }) => padding || '5px 12px'};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Blue-08, #edf4ff);
  background-clip: padding-box;
`;

const SourceSavvyText = styled('label')`
  color: ${({ theme }) => theme.colors.blue.primaryA};
  font-family: ${({ theme }) => theme.fonts.wallop};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
`;

const SourceExistInAccountText = styled('label')`
  color: ${({ theme }) => theme.colors.gray.c12};
  font-family: ${({ theme }) => theme.fonts.inter};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.36px;
`;
