import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { LLMSelectOption } from './llm-select-option';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { Stack } from '@/ui/line/line';
import { useTranslation } from 'react-i18next';
import { Icon, type IconVariant } from '@/ui/icons/icon';
import { styled } from 'goober';
import type { AIModelType, ExploreFormState } from '../explore.state';
import { isEllipsis, isLLMOption } from '@/helpers/other';
import { Checkbox } from '@/ui/checkbox/checkbox';
import { sharedProps } from '@/ui/select/select-shared';
import type { DropdownIndicatorProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SingleValueContainer } from '@/ui/select/async/async-select.styles';
import { useMemo, useRef } from 'react';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colors } from '@/theme/colors';

interface LLMOption {
  value: AIModelType;
  label: string;
  icon: IconVariant;
}

const llmOptions: LLMOption[] = [
  { value: 'openai', label: 'OpenAI', icon: 'OpenAI' },
  { value: 'perplexity_lg', label: 'Perplexity Large', icon: 'Perplexity' },
  { value: 'perplexity_hg', label: 'Perplexity Huge', icon: 'Perplexity' },
];

export const LLMModelForm = ({
  value,
  onChange,
}: {
  value: ExploreFormState;
  onChange: (event: {
    model?: AIModelType;
    enabledExploreAccountData?: boolean;
  }) => void;
}) => {
  const { t } = useTranslation('explore');

  const selectedOption = useMemo(
    () => llmOptions.find(llm => llm.value === value.model),
    [value.model],
  );

  return (
    <Stack gap="18px">
      <SectionTitle>{t`settings.sectionTitle`}</SectionTitle>
      <Stack>
        <SelectionLabel>{t`settings.selectDataModel`}</SelectionLabel>
        <RoundBorderedAsyncSelect
          isSearchable={false}
          defaultOptions={llmOptions}
          value={selectedOption}
          isClearable={false}
          menuPosition="fixed"
          onChange={value =>
            isLLMOption(value) &&
            onChange({
              model: value.value,
            })
          }
          isMulti={false}
          components={{
            ...sharedProps.components,
            Option,
            SingleValue,
            DropdownIndicator,
          }}
        />
      </Stack>
      <Checkbox
        id="explore-enable-account-data-checkbox"
        label={<Label>{t`settings.enableAccountData`}</Label>}
        checked={value.enabledExploreAccountData}
        onChange={element =>
          onChange({ enabledExploreAccountData: element.target.checked })
        }
      />
    </Stack>
  );
};

const Option = ({ data, ...props }: AsyncSelectOptionProps) => {
  if (isLLMOption(data)) {
    return (
      <LLMSelectOption
        {...props}
        data={data}
        icon={<Icon icon={data.icon} />}
      />
    );
  }

  return null;
};

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<unknown, boolean>) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isLLMOption(props.data)) {
    return null;
  }

  return (
    <components.SingleValue {...props}>
      <Tooltip
        placement="bottom"
        content={children}
        visible={isEllipsis(ref.current)}
      >
        <SingleValueContainer ref={ref}>
          <Flexbox gap="8px" alignItems="center">
            <Icon icon={props.data.icon} />
            <Label>{children}</Label>
          </Flexbox>
        </SingleValueContainer>
      </Tooltip>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Flexbox alignItems="center">
        <Icon icon="CaretDown" color={colors.gray.c7} />
      </Flexbox>
    </components.DropdownIndicator>
  );
};

const Label = styled('p')`
  ${({ theme }) =>
    theme.typography.exploreSettings.aiModelDropDownSelectedValue}
`;

const RoundBorderedAsyncSelect = styled(AsyncSelect)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blue.c6};
`;

const SectionTitle = styled('div')`
  ${({ theme }) => theme.typography.exploreSettings.sectionTitle}
`;

const SelectionLabel = styled('div')`
  ${({ theme }) => theme.typography.exploreSettings.selectionLabel}
`;
