import { forwardRef, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  exploreFormState,
  generatedShortDescriptionsState,
} from '@/features/explore/explore.state';
import { SmallText } from '@/ui/typography/widgets';

import { useCreateCompanyDescription } from './use-create-company-description-mutation';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@/config/query-client';
import type { ExploreOrganizationSuggestion } from '@/api/v4/organization-suggestions.api';
import {
  isDescriptionNotFounded,
  sortByShortDescriptionAndRelevancy,
} from '@/features/explore/sort-by-short-description-and-result-number';

type CompanyDescriptionLoaderProps = {
  companyName: string;
  companyWebsite?: string;
  shortDescription?: string;
  requestId: string;
  resultNumber: number;
  dataQueryKeyToSort?: string;
};

export const CompanyDescriptionLoader = ({
  companyName,
  companyWebsite,
  shortDescription,
  requestId,
  resultNumber,
  dataQueryKeyToSort,
}: CompanyDescriptionLoaderProps) => {
  const [generatedDescriptions, setGeneratedDescriptions] = useRecoilState(
    generatedShortDescriptionsState,
  );

  const exploreFormStateData = useRecoilValue(exploreFormState);

  const { data, isLoading, mutate } = useCreateCompanyDescription();
  const queryClient = useQueryClient();
  const suggestionsQuery =
    queryClient.getQueryData<ExploreOrganizationSuggestion>([
      QueryKey.ExploreSuggestions,
    ]);

  useEffect(() => {
    if (!getShortDescription() && companyName) {
      mutate(
        {
          requestId,
          resultNumber,
          companyName,
          website: companyWebsite,
          model: exploreFormStateData.model,
        },
        {
          onSuccess: async description => {
            setGeneratedDescriptions(mappings => {
              const newMappings = { ...mappings };
              newMappings[`${requestId}-${resultNumber}`] = description;
              return newMappings;
            });

            if (!isDescriptionNotFounded(description) || !suggestionsQuery)
              return;

            if (dataQueryKeyToSort === QueryKey.ExploreSuggestions) {
              const sortedResults = sortByShortDescriptionAndRelevancy(
                suggestionsQuery.results,
              );

              queryClient.setQueryData([QueryKey.ExploreSuggestions], {
                ...suggestionsQuery,
                results: sortedResults,
              });
            }
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShortDescription = () => {
    return (
      shortDescription ||
      generatedDescriptions[`${requestId}-${resultNumber}`] ||
      data
    );
  };

  if (!getShortDescription() || isLoading) {
    return (
      <ShortDescription>
        <Skeleton width="393px" height="13px" />
        <Skeleton width="292px" height="13px" />
      </ShortDescription>
    );
  }
  return <ShortDescription>{getShortDescription()}</ShortDescription>;
};

const ShortDescription = styled(SmallText, forwardRef)`
  ${({ theme }) => theme.typography.enhance.tableSmallText}
  ${({ theme }) => theme.mixins.lineClamp(2)}
  padding: 15px 0px;
`;
