import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const getValidationSchema = (isCompanyRequired: boolean) => {
  return z.object({
    title: z.string().optional(),
    date: z.string().nonempty(t`default:required`),
    relatedCompanyId: isCompanyRequired
      ? z.string().nonempty(t`default:required`)
      : z.string().optional(),
    relatedProjectId: z.string().optional().nullable(),
    relatedTeamId: z.string().optional().nullable(),
    summary: z.string().optional(),
    attendees: z
      .array(
        z.object({
          value: z.string(),
          label: z.string().nonempty(),
          entity: z.object({ id: z.string(), name: z.string() }).optional(),
          type: z.enum(['team_contact', 'organization', 'crunchbase', 'user']),
        }),
      )
      .optional(),
    mentionedUsers: z.array(z.number()).optional(),
  });
};
