/* eslint-disable @typescript-eslint/no-floating-promises */
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';

import type {
  SearchSuggestion,
  SearchSuggestionQuery,
} from '@/api/v4/search.api';
import { fetchSearchSuggestions } from '@/api/v4/search.api';
import { notify } from '@/ui/snackbar/notify';

/**
 * Custom hook for search results that doesn't rely on React Query's caching
 * to ensure API is called every time, even for identical searches
 */
export const useSearchResults = (query: SearchSuggestionQuery) => {
  const { t } = useTranslation('default');
  const [allResults, setAllResults] = useState<SearchSuggestion[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [isFullSearchLoading, setIsFullSearchLoading] = useState(false); // For initial/new searches
  const [isAnyRequestInProgress, setIsAnyRequestInProgress] = useState(false); // For any API activity
  const [error, setError] = useState<Error | null>(null);
  const [searchTrigger, setSearchTrigger] = useState(0);
  const previousQueryRef = useRef<SearchSuggestionQuery | null>(null);
  const searchInProgressRef = useRef(false);
  const isFirstRenderRef = useRef(true);

  // Function to manually trigger a search
  const triggerSearch = useCallback(() => {
    setSearchTrigger(prev => prev + 1);
  }, []);

  // Check if query has meaningfully changed
  const hasQueryChanged = useCallback(() => {
    if (!previousQueryRef.current) return true;

    const prev = previousQueryRef.current;

    // For simpler comparison, we check if any significant query parameter changed
    return (
      prev.search !== query.search ||
      prev.pageSize !== query.pageSize ||
      JSON.stringify(prev.entities) !== JSON.stringify(query.entities) ||
      prev.page !== query.page
    );
  }, [query]);

  // Perform the actual search
  const performSearch = useCallback(async () => {
    // Skip empty searches
    if (!query.search) {
      setAllResults([]);
      setTotalResults(0);
      return;
    }

    // Prevent concurrent searches and double fetches
    if (searchInProgressRef.current) return;

    // Mark search as in progress
    searchInProgressRef.current = true;

    // Any request is now in progress - this is for "loading" indicators like buttons, spinners, etc.
    setIsAnyRequestInProgress(true);

    // Determine if this is a pagination request (page > 1 and only the page changed)
    const isPagination =
      previousQueryRef.current &&
      previousQueryRef.current.search === query.search &&
      query.page &&
      query.page > 1 &&
      JSON.stringify(previousQueryRef.current.entities) ===
        JSON.stringify(query.entities) &&
      previousQueryRef.current.pageSize === query.pageSize &&
      previousQueryRef.current.page !== query.page;

    // For new searches (not pagination), show the full page loading state
    if (!isPagination) {
      setIsFullSearchLoading(true);
    }

    try {
      const response = await fetchSearchSuggestions(query);

      if (isPagination) {
        // For pagination, append results
        setAllResults(prev => [...prev, ...response.results]);
      } else {
        // For new searches, replace results
        setAllResults(response.results);
      }

      setTotalResults(response.total);
      setError(null);
    } catch (err) {
      setError(err as Error);
      notify({
        message: t`unknownError`,
      });
    } finally {
      // Clear all loading states
      setIsFullSearchLoading(false);
      setIsAnyRequestInProgress(false);
      searchInProgressRef.current = false;

      // Update reference to current query for future comparisons
      previousQueryRef.current = { ...query };
    }
  }, [query, t]);

  // Execute search when:
  // 1. Query changes meaningfully (search term, page, etc.)
  // 2. Search is manually triggered (even with the same parameters)
  useEffect(() => {
    // Skip search on first render to prevent double fetching
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      // Don't return early if there's already a search term
      if (!query.search) {
        return;
      }
    }

    const shouldSearch = hasQueryChanged() || searchTrigger > 0;

    if (shouldSearch) {
      performSearch();
    }
  }, [query, searchTrigger, hasQueryChanged, performSearch]);

  // Return data in the expected format, mapping our improved naming back to the expected interface
  return {
    data: {
      total: totalResults,
      results: allResults,
    },
    isLoading: isFullSearchLoading, // For full page loading states
    isFetching: isAnyRequestInProgress, // For any ongoing request
    error,
    refetch: triggerSearch,
  };
};
