import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';

import { Inline } from '@/ui/line/line';

import { SearchContentContainer } from './content-container';

export const SearchLoadingSkeleton = ({ columns }: { columns: number }) => (
  <SearchContentContainer gap="0" data-testid="search-loading-container">
    {Array.from({ length: columns }).map((_, cellIndex) => (
      <SkeletonItem key={cellIndex}>
        <Inline templateColumns="5% auto">
          <Skeleton circle width="20px" height="20px" />
          <div>
            <Skeleton height="15px" width="70%" />
            <Skeleton height="11px" width="40%" />
          </div>
        </Inline>
      </SkeletonItem>
    ))}
  </SearchContentContainer>
);

const SkeletonItem = styled('div')`
  padding: 14px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c3};
`;
