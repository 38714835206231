import { Stack } from '@/ui/line/line';
import { LinkIcon } from '@/ui/link/link-icon';
import { Logo } from '@/ui/logo/logo';
import { colors } from '@/theme/colors';
import { prepareLink } from '@/utils/link';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { TablePrimaryCell } from '@/ui/table/infinite-table/table-cells';
import { Tooltip } from '@/ui/tooltip/tooltip';
import Skeleton from 'react-loading-skeleton';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { formatLocation } from '@/helpers/format-location';
import { useTranslation } from 'react-i18next';
import { SmallText } from '@/ui/typography/widgets';
import { type ExploreResult } from '../../explore.state';
import { useCompanyData } from './use-company-data.query';

type ExploreResultsCompanyItemRowProps = {
  data: ExploreResult;
  isLoading?: boolean;
};

export const ExploreResultsCompanyItemBaseInfo = ({
  data,
  isLoading,
}: ExploreResultsCompanyItemRowProps) => {
  const { t } = useTranslation('companies');

  const { data: cbData } = useCompanyData(data.crunchbaseId ?? undefined, {
    enabled: Boolean(data.crunchbaseId && !data.organizationId),
  });

  const hqLocation = cbData?.hqLocation;
  const locationText = hqLocation
    ? formatLocation(
        {
          cityName: hqLocation.city?.name,
          regionName: hqLocation.region?.name,
          countryName: hqLocation.country?.name,
        },
        { compact: true },
      )
    : t`companies.noLocation`;

  const linkedInUrl = cbData?.linkedInUrl ?? data.linkedInUrl;

  return (
    <>
      <Logo
        name={data.name ?? ''}
        logoUrl={data.logoUrl}
        singleLetter
        bgColor={data.logoUrl ? 'transparent' : colors.accent.green.c3}
        border={data.logoUrl ? `2px solid ${colors.gray.c3}` : undefined}
      />
      <TablePrimaryCell>
        <Flexbox direction="row" gap="100px" alignItems="center" fullWidth>
          <Stack gap="2px" width="160px">
            <EllipsisTextTooltip
              text={data.name}
              Component={
                data.crunchbaseId ? (
                  <a
                    href={prepareLink(data.website)}
                    target="_blank"
                    rel="noreferrer"
                  />
                ) : (
                  <>{data.name}</>
                )
              }
            />
            {isLoading && <Skeleton width="125px" height="12px" />}
            {!isLoading && (
              <Tooltip
                disabled={!cbData?.hqLocation}
                content={formatLocation({
                  cityName: cbData?.hqLocation?.city?.name,
                  regionName: cbData?.hqLocation?.region?.name,
                  countryName: cbData?.hqLocation?.country?.name,
                })}
              >
                {<SmallText>{locationText}</SmallText>}
              </Tooltip>
            )}
          </Stack>
          <Flexbox gap="8px" flex="auto">
            <Tooltip content={data.website}>
              <LinkIcon
                disableTooltip
                url={data.website ?? ''}
                icon="Website"
                color={data.website ? '#007AFF' : '#D6DDF8'}
              />
            </Tooltip>
            <Tooltip content={linkedInUrl}>
              <LinkIcon
                disableTooltip
                url={linkedInUrl ?? ''}
                icon="LinkedIn"
                color={linkedInUrl ? '#0A66C2' : '#D6DDF8'}
              />
            </Tooltip>
          </Flexbox>
        </Flexbox>
      </TablePrimaryCell>
    </>
  );
};
