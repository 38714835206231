import { useEffect } from 'react';
import { usePrefetchCompanyData } from './use-prefetch-company-data';

export const CompanyDataPrefetcher: React.FC<{ crunchbaseIds: string[] }> = ({
  crunchbaseIds,
}) => {
  const prefetchCompanies = usePrefetchCompanyData();

  // Prefetch on component mount or when IDs change
  useEffect(() => {
    if (crunchbaseIds.length > 0) {
      prefetchCompanies(crunchbaseIds).catch(error => {
        console.error('Error in prefetch operation:', error);
      });
    }
  }, [crunchbaseIds, prefetchCompanies]);

  return null;
};
