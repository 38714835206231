import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as EmptyHistoryIllustration } from '@/assets/svg/history.svg';
import { LoadableList } from '@/components/loadable-list/loadable-list';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { ParagraphSmall } from '@/ui/typography/widgets';

import {
  exploreFormState,
  isExploreRequestHistoryPaneOpenState,
} from '../explore.state';
import { EXPLORE_HISTORY_STATE } from '../use-explore-navigation-history';

import { RequestsGroup } from './requests-group';
import { useExploreHistory } from './use-explore-history';
import { useSortedExploreHistory } from './use-sorted-explore-history';
import { LLMModelForm } from './llm-model-form';

export const ExploreHistoryPane = ({
  isClosable,
  onClose,
  className,
}: {
  isClosable?: boolean;
  onClose?: () => void;
  className?: string;
}) => {
  const { t } = useTranslation('explore');
  const { isLoading, fetchNextPage, hasNextPage } = useExploreHistory();
  const { sortedRequests } = useSortedExploreHistory();
  const setIsHistoryPaneOpen = useSetRecoilState(
    isExploreRequestHistoryPaneOpenState,
  );
  const [exploreFormStateData, setExploreFormState] =
    useRecoilState(exploreFormState);

  const navigateTo = useNavigateTo();
  const isEmptyState = !sortedRequests;

  return (
    <Container
      gap="24px"
      className={className}
      fullHeight
      alignTop={isEmptyState}
    >
      <HeaderContent>
        <Stack fullWidth>
          <Flexbox name="explore-history-pane-header">
            {isClosable && onClose ? (
              <Arrow
                icon="ArrowLeftBack"
                width="24px"
                height="24px"
                color={colors.basics.black}
                onClick={onClose}
              />
            ) : null}
            <HeaderTitle>{t`history`}</HeaderTitle>
          </Flexbox>
          {isEmptyState && <Text>{t`emptyHistoryText`}</Text>}
        </Stack>
        {isLoading ? (
          <Stack gap="24px">
            <Skeleton width="50px" />
            <Stack gap="8px">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          </Stack>
        ) : isEmptyState ? (
          <Flexbox
            name="explore-history-image"
            justify="center"
            alignItems="center"
            direction="column"
            grow={1}
          >
            <EmptyHistoryIllustration />
          </Flexbox>
        ) : (
          <List onLoadMore={fetchNextPage} hasMore={!!hasNextPage}>
            {sortedRequests.map(requestsPerDate => {
              return (
                <RequestsGroup
                  key={requestsPerDate.period}
                  groupTitle={requestsPerDate.period}
                  requests={requestsPerDate.requests}
                  onRequestClick={requestId => {
                    navigateTo.exploreResults(
                      { requestId },
                      {
                        state: { from: EXPLORE_HISTORY_STATE },
                      },
                    );
                    setIsHistoryPaneOpen(false);
                  }}
                />
              );
            })}
          </List>
        )}
      </HeaderContent>
      <LLMModelForm
        value={exploreFormStateData}
        onChange={v =>
          setExploreFormState({
            enabledExploreAccountData:
              v.enabledExploreAccountData ??
              exploreFormStateData.enabledExploreAccountData,
            model: v.model ?? exploreFormStateData.model,
          })
        }
      />
    </Container>
  );
};

const HeaderContent = styled('div')``;

const Container = styled(Stack)<{ fullHeight?: boolean; alignTop?: boolean }>`
  padding: 19px 26px 0px;
  ${({ fullHeight }) => fullHeight && 'height: 100%'};
  ${({ alignTop }) => alignTop && 'align-items: flex-start'};
  grid-template-rows: 78% 18%;
`;

const HeaderTitle = styled('h1')`
  ${({ theme }) => theme.typography.widget.emptyState};
`;

const Arrow = styled(Icon)`
  transform: rotate3d(0, 0, 1, 180deg);
  margin-right: 10px;
`;

const Text = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.gray.c13};
`;

const List = styled(LoadableList)`
  max-height: calc(82vh - 100px);
  gap: 24px;
`;
