import { useFormContext } from 'react-hook-form';

import { isDefaultOption } from '@/helpers/other';

import type { ProjectHeaderForm } from './use-project-header-form';
import { FormSelect } from '@/features/explore/ui/form-select';
import { styled } from 'goober';
import { colors } from '@/theme/colors';
import type { ProjectType } from '@/api/v4/project-types.api';
import { useProjectTypes } from '../../use-project-types.query';
import { useMemo } from 'react';

const mapProjectTypesToOptions = (projectTypes: ProjectType[]) => {
  return projectTypes.map(projectType => ({
    label: projectType.name,
    value: projectType.id,
  }));
};

export const TypeSelect = () => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<ProjectHeaderForm>();
  const { data } = useProjectTypes();

  const typeDefaultValue = defaultValues?.type ?? null;

  const typeOptions = useMemo(() => {
    return data ? mapProjectTypesToOptions(data) : [];
  }, [data]);

  return (
    <SelectWrapper>
      <FormSelect
        dataTestId="project-type-select"
        autoFocus
        defaultValue={typeDefaultValue}
        setOptions={typeOptions}
        onChange={(selected: unknown, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setValue('type', null, {
              shouldDirty: true,
            });
          }

          if (isDefaultOption(selected)) {
            setValue('type', selected, {
              shouldDirty: true,
            });
          }
        }}
        backgroundColor="white"
        variant="small"
        dropdownIndicatorColor={colors.basics.black}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div')`
  width: 140px;
`;
