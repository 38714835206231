import type { APIResponse } from './base-fetch';
import { get } from './base-fetch';

type CrunchbaseSearchResult = APIResponse<'/api/v4/crunchbase/search'>[number];
type Location = {
  name: string;
};
// null return type is not possible for APIResponse, so we need to use a custom type
export type SavvyCrunchbaseOrganizationResponse = {
  uuid: string;
  lastFundingDate: Date | null;
  hqLocation: {
    city: Location;
    region: Location;
    country: Location;
  } | null;
  fundingTotal: number | null;
  lastFundingType: string | null;
  linkedInUrl: string | null;
};

export const fetchCrunchbaseSearchResults = async (searchPhrase: string) =>
  await get<CrunchbaseSearchResult[]>(
    '/v4/crunchbase/search?search=' + searchPhrase,
  );

export const fetchCompanyCrunchbaseDataByCrunchbaseId = async (
  crunchbaseId: string,
) =>
  await get<SavvyCrunchbaseOrganizationResponse>(
    '/v4/crunchbase/' + crunchbaseId,
  );
