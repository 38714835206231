import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { projectIdState } from '../project.state';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { fetchProjectListingsCount } from '@/api/v4/project-listings.api';

export const useProjectListingsCount = () => {
  const projectId = useRecoilValue(projectIdState);

  return useQuery(
    [QueryKey.ProjectListingsCount, projectId],
    () => (projectId ? fetchProjectListingsCount(projectId) : undefined),
    {
      staleTime: getTimeInMs(5, 'minute'),
    },
  );
};
