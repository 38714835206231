import type { ISort } from '@/ui/table/table.types';

import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type ProjectCompaniesSort =
  | 'companyName'
  | 'status'
  | 'yearEstablished'
  | 'fundingStage'
  | 'capitalRaised'
  | 'averageRating'
  | 'useCaseFit'
  | 'createdDate';

export type ProjectListingsResponse =
  APIResponse<'/api/v4/projects/{projectId}/project-listings'>;

export type ProjectListing = ProjectListingsResponse['records'][number];

export type ProjectOnList = APIResponse<'/api/v4/projects'>[number];
export type ProjectRequestParams = Omit<
  APIRequestQueryParams<'/api/v4/projects'>,
  'startDate' | 'endDate'
> & {
  createdAt?: {
    startDate: string;
    endDate: string;
  };
};

export enum ProjectStatus {
  active = 'active',
  onHold = 'on_hold',
  stuck = 'stuck',
  done = 'done',
}

export type ProjectSortOptions = NonNullable<ProjectRequestParams['sort']>;
export type ProjectKPIs = APIResponse<'/api/v4/projects/kpi'>;

export type Project = APIResponse<'/api/v4/projects/{projectId}'>;

export const fetchProject = async (projectId: string) =>
  get<Project>(`v4/projects/${projectId}`);

type CreateProjectResponse = APIResponse<'/api/v4/projects/create'>;
export type CreateProjectPayload = APIRequestBody<'/api/v4/projects/create'>;
type ProjectListingsParams =
  APIRequestQueryParams<'/api/v4/projects/{projectId}/project-listings'>;

export const createProject = async (payload: CreateProjectPayload) =>
  post<CreateProjectResponse>(`v4/projects/create`, payload);

type EditProjectResponse = APIResponse<'/api/v4/projects/{projectId}/edit'>;
export type EditProjectPayload =
  APIRequestBody<'/api/v4/projects/{projectId}/edit'>;

export type ProjectCompanyListing =
  APIResponse<'/api/v4/projects/{projectId}/companies'>[number];

export type ProjectCompanyListingParams =
  APIRequestQueryParams<'/api/v4/projects/{projectId}/companies'>;

export const editProject = async (
  projectId: string,
  payload: EditProjectPayload,
) =>
  put<EditProjectResponse, EditProjectPayload>(
    `v4/projects/${projectId}/edit`,
    payload,
  );

type DeleteProjectResponse = APIResponse<'/api/v4/projects/{projectId}/delete'>;

export const deleteProject = async (projectId: string) =>
  remove<DeleteProjectResponse>(`v4/projects/${projectId}/delete`);

type ProjectListingsListParams = ISort<ProjectCompaniesSort> &
  ProjectListingsParams;

export const fetchProjectListings = async (
  projectId: string,
  params?: ProjectListingsListParams,
): Promise<ProjectListingsResponse> => {
  return get<ProjectListingsResponse>(
    `v4/projects/${projectId}/project-listings`,
    {
      params,
    },
  );
};

export const fetchProjectCompanies = async (
  projectId: string,
  params?: ProjectCompanyListingParams,
): Promise<ProjectCompanyListing[]> => {
  return get<ProjectCompanyListing[]>(`v4/projects/${projectId}/companies`, {
    params,
  });
};

export const fetchProjects = async (params: ProjectRequestParams) => {
  return get<ProjectOnList[]>(`v4/projects`, {
    params,
  });
};

export const fetchProjectKPIs = async (params: ProjectRequestParams) =>
  get<ProjectKPIs>(`v4/projects/kpi`, {
    params,
  });

export type ShareProjectBody =
  APIRequestBody<'/api/v4/projects/{projectId}/share'>;

export const shareProject = async (projectId: string, body: ShareProjectBody) =>
  post<void>(`v4/projects/${projectId}/share`, body);

export type ProjectOption = APIResponse<'/api/v4/projects/options'>[number];
export type ProjectOptionsQuery =
  APIRequestQueryParams<'/api/v4/projects/options'>;

export const fetchProjectOptions = (params: ProjectOptionsQuery) =>
  get<ProjectOption[]>('v4/projects/options', { params });

export const fetchProjectOptionById = (projectId: string) =>
  get<ProjectOption>(`v4/projects/options/${projectId}`);

export const uploadCompaniesToProject = async (
  projectId: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('file', file);

  return post<Blob>(`/v4/projects/${projectId}/load-companies`, formData, {
    responseType: 'blob',
  })
    .then(async response => {
      const responseText = await response.text();
      if (response instanceof Blob && responseText.length > 0) {
        const downloadURL = URL.createObjectURL(response); // Create an object URL for the binary data
        return downloadURL;
      }

      return null;
    })
    .catch(error => {
      console.error('Error uploading or downloading the file:', error);
    });
};

export const exportToCSV = (projectId: string, projectName: string) =>
  post<Blob>(`v4/projects/${projectId}/export-to-csv`, null, {
    responseType: 'blob', // Ensures the response is received as binary data
  })
    .then(response => {
      if (response instanceof Blob) {
        // Create an object URL for the binary data
        const downloadURL = URL.createObjectURL(response);

        // Use project name in the filename
        const filename = `${projectName}.csv`;

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename; // Set the filename with project name
        link.click(); // Trigger the download
      } else {
        console.error('Unexpected response format. Expected Blob.');
      }
    })
    .catch(error => {
      console.error('Error exporting file:', error);
    });
