import { useRecoilValue } from 'recoil';
import { isProjectHeaderEditMode } from './project-header.state';
import { Inline } from '@/ui/line/line';
import { StatusSelect } from './edit/status-select';
import { TypeSelect } from './edit/type-select';
import type { Project } from '@/api/v4/projects.api';
import { StatusIndicator } from '../../status-indicator';
import { styled } from 'goober';
import { SmallText } from '@/ui/typography/widgets';
import { useTranslation } from 'react-i18next';

interface ProjectSecondRowProps {
  projectType?: Project['type'] | null;
  projectStatus?: Project['status'] | null;
}

export const ProjectHeaderSecondRow = ({
  projectType,
  projectStatus,
}: ProjectSecondRowProps) => {
  const { t } = useTranslation('projects', { keyPrefix: 'projectOverview' });
  const isEditMode = useRecoilValue(isProjectHeaderEditMode);

  if (isEditMode) {
    return (
      <Inline justifyContent="flex-start" margin="24px 0 0 0">
        <StatusSelect />
        <TypeSelect />
      </Inline>
    );
  }

  return (
    <Inline justifyContent="flex-start">
      {projectStatus ? (
        <StatusIndicator status={projectStatus} />
      ) : (
        <Text isPlaceholder>{t`status`}</Text>
      )}
      <Separator />

      <Text isPlaceholder={Boolean(!projectType?.name)}>
        {projectType?.name ?? t`projectType`}
      </Text>
    </Inline>
  );
};

const Separator = styled('div')`
  margin: 0 8px;
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.gray.c5};
`;

const Text = styled(SmallText)<{ isPlaceholder: boolean }>`
  color: ${({ theme, isPlaceholder }) =>
    isPlaceholder ? theme.colors.gray.c11 : theme.colors.basics.black};
`;
