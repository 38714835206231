import type { SearchSuggestion } from '@/api/v4/search.api';
import { SearchResults } from './search-results';
import type { SearchResultsFilter } from './search.state';
import { searchResultsFilters, searchResultsFilterState } from './search.state';
import { useRecoilState } from 'recoil';
import { Stack } from '@/ui/line/line';
import { useCallback, useMemo } from 'react';
import { SearchResultsFilters } from './search-results-filters';
import { useTranslation } from 'react-i18next';
import { CompanySuggestionsTable } from './search-suggestions/company-suggestions-table/company-suggestions-table';
import { ProjectSuggestionsTable } from './search-suggestions/project-suggestions-table/project-suggestions-table';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Small } from '@/ui/typography/across-platform';

export const SearchResultsContent = ({
  results: searchSuggestions,
  totalResults,
  hasNextPage,
  onShowMore,
  isLoading,
}: {
  results: SearchSuggestion[];
  totalResults: number;
  hasNextPage?: boolean;
  onShowMore?: () => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    searchResultsFilterState,
  );

  const getResultsQuantityPerType = useCallback(
    (type: SearchSuggestion['type']) => {
      return searchSuggestions.filter(result => result.type === type).length;
    },
    [searchSuggestions],
  );

  const filtersConfig = useMemo(
    () =>
      searchResultsFilters.map(filter => ({
        label: t(`filters.${filter}`),
        type: filter,
        isActive: selectedFilter === filter,
        resultsQuantity:
          filter === 'all'
            ? searchSuggestions.length
            : getResultsQuantityPerType(filter),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getResultsQuantityPerType, searchSuggestions.length, selectedFilter],
  );

  const getContent = useCallback(
    (selectedFilter: SearchResultsFilter, filteredData: SearchSuggestion[]) => {
      switch (selectedFilter) {
        case 'project':
          return <ProjectSuggestionsTable data={filteredData} />;
        case 'company':
          return <CompanySuggestionsTable data={filteredData} />;
        default:
          return (
            <>
              <SearchResults
                results={filteredData}
                hasNextPage={hasNextPage}
                onShowMore={onShowMore}
                isLoading={isLoading}
              />
              {totalResults && (
                <Flexbox justify="end" dataTestId="search-results-counter">
                  <Small>
                    {t('result', {
                      count: totalResults,
                    })}
                  </Small>
                </Flexbox>
              )}
            </>
          );
      }
    },
    [hasNextPage, onShowMore, isLoading, totalResults],
  );

  const filteredResults = useMemo(() => {
    if (selectedFilter === 'all') {
      return searchSuggestions;
    }

    return searchSuggestions.filter(
      suggestion => suggestion.type === selectedFilter,
    );
  }, [searchSuggestions, selectedFilter]);

  const onFilterClick = (filter: SearchResultsFilter) =>
    setSelectedFilter(filter);

  return (
    <Stack gap="30px">
      <SearchResultsFilters
        filters={filtersConfig}
        onFilterClick={onFilterClick}
      />
      {getContent(selectedFilter, filteredResults)}
    </Stack>
  );
};
