import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isProjectOption } from '@/helpers/other';

import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { useMeetingDefaultProjectOption } from '../hooks/use-meeting-default-project-option';
import { FormSelect } from '../ui/form-select';
import { WithLabel } from '../ui/with-label';
import { fetchProjectOptions } from '@/api/v4/projects.api';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';
import { mapProjectOptions } from '../hooks/use-project-options.query';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

export const RelatedProjectSelect = ({
  dependencyKey,
  withTeamField,
}: {
  dependencyKey?: string | null;
  withTeamField?: boolean;
}) => {
  const { t } = useTranslation('default');
  const { setValue, watch } = useFormContext<MeetingFormFields>();
  const { defaultProjectOption } = useMeetingDefaultProjectOption();

  const relatedTeamId = watch('relatedTeamId');
  const relatedCompanyId = watch('relatedCompanyId');

  const projectOptions: AsyncSelectProps['setOptions'] = async (
    searchPhrase: string,
    page?: number,
  ) => {
    const options = await fetchProjectOptions({
      organizationId: relatedCompanyId,
      teamId: relatedTeamId ?? undefined,
      search: prepareSafeInput(searchPhrase),
      page,
      items: 20,
    });
    return mapProjectOptions(options);
  };

  return (
    <WithLabel
      name="meeting-related-project"
      labelText={t`actionPanel.meetings.relatedProject`}
    >
      <FormSelect
        key={dependencyKey}
        defaultValue={defaultProjectOption}
        setOptions={projectOptions}
        onChange={async newValue => {
          if (isProjectOption(newValue)) {
            setValue('relatedProjectId', newValue.value, {
              shouldDirty: true,
            });

            if (withTeamField && newValue.createdFor) {
              setValue('relatedTeamId', newValue.createdFor, {
                shouldDirty: true,
              });
            }
          } else {
            setValue('relatedProjectId', null, {
              shouldDirty: true,
            });
          }
        }}
      />
    </WithLabel>
  );
};
