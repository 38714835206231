import { styled } from 'goober';
import type { ChangeEvent } from 'react';
import { useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SEARCH_INPUT_MAX_CHAR } from '@/constants';
import { useFocusOnMount } from '@/hooks/use-focus-on-mount';
import { colors } from '@/theme/colors';
import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';

import { useDebouncedCallback } from 'use-debounce';
import { SearchContent } from './search-content';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { searchQueryState } from './search.state';

export const Search = () => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });

  const setSearchQuery = useSetRecoilState(searchQueryState);
  const resetSearchQuery = useResetRecoilState(searchQueryState);
  const searchRef = useRef<HTMLInputElement>(null);
  useFocusOnMount(searchRef);

  // Create a debounced version of setSearchQuery
  const debouncedSetSearchQuery = useDebouncedCallback(
    (value: string) => {
      setSearchQuery(prevQuery => ({
        ...prevQuery,
        search: value,
        page: 1,
      }));
    },
    500,
    { maxWait: 1000, leading: true },
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
      resetSearchQuery();
    };
  }, [debouncedSetSearchQuery]);

  // Immediate handler for the input field
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ?? '';
      debouncedSetSearchQuery(value);
    },
    [debouncedSetSearchQuery],
  );

  return (
    <SearchContainer gap="20px">
      <Input
        name="search"
        ref={searchRef}
        leftIcon="Search"
        iconSize="26px"
        iconColor={colors.gray.c7}
        padding="3px 8px"
        fontSize="12px"
        gap="8px"
        placeholder={t`searchPlaceholder`}
        onInput={handleChange}
        maxLength={SEARCH_INPUT_MAX_CHAR}
      />
      <SearchContent />
    </SearchContainer>
  );
};

const SearchContainer = styled(Stack)`
  height: 100%;
  flex: 1;
`;
