import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isCompanyOption } from '@/helpers/other';
import { colors } from '@/theme/colors';
import { OptionWithLogo } from '@/ui/select/async/components/option/option-with-logo';
import { SingleValueWithLogo } from '@/ui/select/async/components/single-value/single-value-with-logo';

import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { FieldError } from '../ui/field-error';
import { FormSelect } from '../ui/form-select';
import { WithLabel } from '../ui/with-label';
import { fetchOrganizationOptions } from '@/api/v4/organization.api';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import {
  mapOrganizationOptions,
  useCountCompanyOptions,
} from '../hooks/use-count-company-options.query';
import { useMeetingDefaultCompanyOption } from '../hooks/use-meeting-default-company-option';

export const RelatedCompanySelect = ({
  isRequired,
}: {
  isRequired?: boolean;
}) => {
  const { t } = useTranslation('default');
  const { setValue, watch, getFieldState, formState } =
    useFormContext<MeetingFormFields>();
  const { defaultCompanyOption } = useMeetingDefaultCompanyOption();
  const relatedProjectId = watch('relatedProjectId');
  const companyFieldState = getFieldState('relatedCompanyId', formState);

  const { data: optionsCount } = useCountCompanyOptions(
    relatedProjectId ?? undefined,
  );

  const noCompaniesWhenRequired = optionsCount === 0 && isRequired;
  const companyOptions: AsyncSelectProps['setOptions'] = async (
    searchPhrase: string,
    page?: number,
  ) => {
    const options = await fetchOrganizationOptions({
      projectId: relatedProjectId ?? undefined,
      search: prepareSafeInput(searchPhrase),
      page,
      items: 20,
    });
    return mapOrganizationOptions(options);
  };

  const placeholderConfig = useMemo(() => {
    if (noCompaniesWhenRequired) {
      return {
        value: t`actionPanel.meetings.noCompanies`,
        color: colors.basics.black,
      };
    }

    return { value: t`select`, color: colors.gray.c7 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsCount, isRequired]);

  return (
    <WithLabel
      name="meeting-related-company"
      labelText={t`actionPanel.meetings.relatedCompany`}
    >
      <FormSelect
        key={relatedProjectId}
        placeholder={placeholderConfig.value}
        placeholderColor={placeholderConfig.color}
        isDisabled={noCompaniesWhenRequired}
        setOptions={companyOptions}
        components={{
          Option: props =>
            OptionWithLogo({
              ...props,
              logoProps: { bgColor: colors.accent.green.c3 },
              dataTestId: 'meeting-related-company-option',
            }),
          SingleValue: props =>
            SingleValueWithLogo({
              ...props,
              logoProps: { bgColor: colors.accent.green.c3 },
            }),
        }}
        defaultValue={defaultCompanyOption}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setValue('relatedCompanyId', undefined, {
              shouldDirty: true,
              shouldValidate: isRequired,
            });
          }

          isCompanyOption(newValue) &&
            setValue('relatedCompanyId', newValue.value, {
              shouldDirty: true,
              shouldValidate: isRequired,
            });
        }}
        withError={companyFieldState.invalid}
      />
      {companyFieldState.invalid && (
        <FieldError absolute fontSize="12px" errorAlign="right">
          {companyFieldState.error?.message}
        </FieldError>
      )}
    </WithLabel>
  );
};
