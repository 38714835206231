import type { StageCount } from '@/api/v4/project-listings.api';
import { DealStageName } from '@/features/listing-stage/types';

export const getDealListingsCount = (stagesCount: StageCount[]) => {
  return stagesCount.reduce((count, stageCount) => {
    return Object.values(DealStageName).some(
      name => name === stageCount.stageName,
    )
      ? count + stageCount.count
      : count;
  }, 0);
};
