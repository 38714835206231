import { useRef } from 'react';

import { OptionLabel } from '@/ui/select/async/async-select.styles';
import { CustomOptionWrapper } from '@/ui/select/async/components/option/custom-option-wrapper';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

interface Props extends AsyncSelectOptionProps {
  label: string;
  icon: React.ReactElement;
}

export const LLMSelectOption = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CustomOptionWrapper
      dataTestId="llm-select-option"
      tooltipContent={props.label}
      isTooltipVisible={true}
      {...props}
    >
      {props.icon}
      <OptionLabel ref={ref}>{props.label}</OptionLabel>
    </CustomOptionWrapper>
  );
};
