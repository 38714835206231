import { type ReactNode } from 'react';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import { Stack } from '@/ui/line/line';

import { projectIdState } from '../project.state';
import { projectListingSortState } from '../project-listing.state';

import { ProjectListingsTable } from './project-listings-table/project-listings-table';
import { StageManagementTable } from './stage-management-table/stage-management-table';
import { ProjectListingsHeader } from './project-listings-header';
import { ProjectListingsLoadingSkeleton } from './project-listings-loading-skeleton';
import { useChangeProjectListingsDisplayMode } from './use-change-project-listings-display-mode';
import { useProjectListings } from './use-project-listings.query';

export type ProjectListingsDisplayMode = 'table' | 'byStage';

export const ProjectListings = ({
  isProjectLoading,
  isDisabledMode,
}: {
  isProjectLoading: boolean;
  isDisabledMode?: boolean;
}) => {
  const { displayMode, onDisplayModeChange } =
    useChangeProjectListingsDisplayMode();
  const projectId = useRecoilValue(projectIdState);
  const sort = useRecoilValue(projectListingSortState);
  const {
    projectListings,
    isLoading: isProjectListingsDataLoading,
    fetchNextPage,
    hasNextPage,
  } = useProjectListings({
    projectId,
    sort,
  });

  const companiesInProjectContentConfig: Record<
    ProjectListingsDisplayMode,
    ReactNode
  > = {
    table: (
      <ProjectListingsTable
        listingsData={projectListings}
        isLoading={isProjectListingsDataLoading}
        onLoadMore={fetchNextPage}
        hasNextPage={Boolean(hasNextPage)}
        isDisabledMode={isDisabledMode}
      />
    ),
    byStage: (
      <StageManagementTable
        listingsData={projectListings}
        isLoading={isProjectListingsDataLoading}
      />
    ),
  };

  return (
    <Wrapper data-testId="project-listings-component">
      {isProjectLoading ? (
        <ProjectListingsLoadingSkeleton />
      ) : (
        <>
          <ProjectListingsHeader
            displayMode={displayMode}
            toggleDisplayMode={onDisplayModeChange}
          />

          {companiesInProjectContentConfig[displayMode]}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)`
  margin-top: 45px;
`;
