import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post } from './base-fetch';

export type SearchSuggestionQuery = APIRequestQueryParams<'/api/v4/search'>;
export type SearchSuggestionResponse = APIResponse<'/api/v4/search'>;
export type SearchSuggestion = SearchSuggestionResponse['results'][number];
export type UserRecentSearchRecord =
  APIResponse<'/api/v4/search/user-recent-records'>[number];

export type AddUserRecentSearchRecordPayload =
  APIRequestBody<'/api/v4/search/user-recent-records/add'>;

type Highlights = SearchSuggestion['highlights'];
export type HighlightKeys = keyof Highlights;

export const fetchSearchSuggestions = async (
  searchQuery: SearchSuggestionQuery,
) =>
  get<SearchSuggestionResponse>('/v4/search', {
    params: searchQuery,
  });

export const fetchUserRecentSearchRecords = async () =>
  get<UserRecentSearchRecord[]>('/v4/search/user-recent-records');

export const addUserRecentSearchRecord = async (
  data: AddUserRecentSearchRecordPayload,
) =>
  post<UserRecentSearchRecord, AddUserRecentSearchRecordPayload>(
    '/v4/search/user-recent-records/add',
    data,
  );
