/* eslint-disable @typescript-eslint/no-floating-promises */
import { useMemo, useCallback } from 'react';
import { EmptyState } from './components/empty-state';
import { InitialState } from './components/initial-state';
import { SearchLoadingSkeleton } from './components/search-loading-skeleton';
import { RecentSearchRecords } from './search-suggestions/user-recent-search/recent-search-records';
import { useRecentSearchRecords } from './search-suggestions/user-recent-search/use-recent-search-records.query';
import { useSearchResults } from './use-search-results.query';

import 'react-loading-skeleton/dist/skeleton.css';
import { SearchResultsContent } from './search-results-content';
import { useRecoilState } from 'recoil';
import { searchQueryState } from './search.state';

export const SearchContent = () => {
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);

  const {
    data: searchResults,
    isFetching: isFetchingSearchResults,
    isLoading: isLoadingSearchResults,
  } = useSearchResults(searchQuery);

  const {
    hasUserSomeRecentSearchRecords,
    data: recentSearchRecords,
    isLoading: isLoadingRecentSearchRecords,
  } = useRecentSearchRecords();

  const isLoading = isFetchingSearchResults || isLoadingRecentSearchRecords;
  const results = searchResults?.results ?? [];
  const hasResults = results.length > 0;

  const recentRecords = useMemo(
    () => recentSearchRecords ?? [],
    [recentSearchRecords],
  );

  const hasNextPage = searchResults
    ? searchResults.results.length < searchResults.total
    : false;

  // Load more results (pagination)
  const doShowMore = useCallback(() => {
    setSearchQuery(prev => ({
      ...prev,
      page: (prev.page ?? 1) + 1,
    }));
  }, [setSearchQuery]);

  if (hasResults) {
    // Only use props that match the SearchResultsContent interface
    return (
      <SearchResultsContent
        results={results}
        hasNextPage={hasNextPage}
        onShowMore={doShowMore}
        isLoading={isLoadingSearchResults || isFetchingSearchResults}
        totalResults={searchResults.total}
      />
    );
  }

  const searchPhrase = searchQuery.search;

  if (isLoading) {
    return <SearchLoadingSkeleton columns={6} />;
  }

  if (searchPhrase && !hasResults) {
    return <EmptyState searchPhrase={searchPhrase} />;
  }

  if (hasUserSomeRecentSearchRecords && !searchPhrase) {
    return <RecentSearchRecords records={recentRecords} />;
  }

  if (!searchPhrase) {
    return <InitialState />;
  }

  return null;
};
