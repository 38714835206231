import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMeetingDefaultTeamOption } from '@/features/action-panel/meetings/hooks/use-meeting-default-team-option';
import { isOption } from '@/helpers/other';

import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { FormSelect } from '../ui/form-select';
import { WithLabel } from '../ui/with-label';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { fetchTeamOptions } from '@/api/v4/team.api';
import { mapTeamsToBasicTeamOptions } from '@/api/v4/mappers/team-basic-option';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';

export const RelatedTeamSelect = () => {
  const { t } = useTranslation('default');
  const { setValue, watch } = useFormContext<MeetingFormFields>();
  const { defaultTeamOption } = useMeetingDefaultTeamOption();
  const relatedProjectId = watch('relatedProjectId');

  const teamOptions: AsyncSelectProps['setOptions'] = async (
    searchPhrase: string,
    page?: number,
  ) => {
    const options = await fetchTeamOptions({
      projectId: relatedProjectId ?? undefined,
      search: prepareSafeInput(searchPhrase),
      page,
      items: 20,
    });
    return mapTeamsToBasicTeamOptions(options);
  };

  return (
    <WithLabel
      name="meeting-related-team"
      labelText={t`actionPanel.meetings.relatedTeam`}
    >
      <FormSelect
        key={relatedProjectId}
        defaultValue={defaultTeamOption}
        setOptions={teamOptions}
        onChange={newValue => {
          if (isOption(newValue)) {
            setValue('relatedTeamId', newValue.value, {
              shouldDirty: true,
            });
          } else {
            setValue('relatedTeamId', null, {
              shouldDirty: true,
            });
          }
        }}
      />
    </WithLabel>
  );
};
