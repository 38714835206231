import { styled } from 'goober';

import { assertNever } from '@/helpers/assert-never';
import { Stack } from '@/ui/line/line';

import { mapHighlights } from './search-suggestions/map-highlights';
import { OrganizationSuggestion } from './search-suggestions/organization-suggestion';
import { ProjectSuggestion } from './search-suggestions/project-suggestion';
import { TagSuggestion } from './search-suggestions/tag-suggestion';
import { TeamSuggestion } from './search-suggestions/team-suggestion';
import { useKeyboardNavigationOnSearchedListItems } from './search-suggestions/use-keyboard-navigation-on-searched-list-items';
import { useSearchedItemClick } from './search-suggestions/use-searched-item-click';
import type { SearchSuggestion } from '@/api/v4/search.api';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { useTranslation } from 'react-i18next';
import { SearchLoadingSkeleton } from './components/search-loading-skeleton';

export const SearchResults = ({
  results: searchSuggestions,
  onShowMore,
  hasNextPage,
  isLoading,
}: {
  results: SearchSuggestion[];
  onShowMore?: () => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });
  const handleSearchedItemClick = useSearchedItemClick();
  const { selectedListItem } = useKeyboardNavigationOnSearchedListItems(
    searchSuggestions.map(suggestion => ({
      id: suggestion.id,
      type: suggestion.type,
    })),
  );

  const mapSuggestion = (suggestion: SearchSuggestion) => {
    switch (suggestion.type) {
      case 'company':
        return (
          <OrganizationSuggestion
            isSelected={suggestion.id === selectedListItem?.id}
            id={suggestion.id}
            key={suggestion.id}
            name={suggestion.name}
            type={suggestion.type}
            logoUrl={suggestion.logoUrl ?? undefined}
            highlights={mapHighlights(suggestion.highlights, suggestion.type)}
            onClick={() =>
              handleSearchedItemClick({
                type: 'company',
                id: suggestion.id,
              })
            }
          />
        );
      case 'project':
        return (
          <ProjectSuggestion
            isSelected={suggestion.id === selectedListItem?.id}
            id={suggestion.id}
            name={suggestion.name}
            type={suggestion.type}
            key={suggestion.id}
            listingsCount={suggestion.listingsCount ?? undefined}
            highlights={mapHighlights(suggestion.highlights, suggestion.type)}
            onClick={() =>
              handleSearchedItemClick({ type: 'project', id: suggestion.id })
            }
          />
        );
      case 'team':
        return (
          <TeamSuggestion
            isSelected={suggestion.id === selectedListItem?.id}
            id={suggestion.id}
            key={suggestion.id}
            name={suggestion.name}
            type={suggestion.type}
            highlights={mapHighlights(suggestion.highlights, suggestion.type)}
            onClick={() =>
              handleSearchedItemClick({ type: 'team', id: suggestion.id })
            }
          />
        );
      case 'tag':
        return <TagSuggestion suggestion={suggestion} key={suggestion.id} />;

      default:
        assertNever(suggestion.type);
    }
  };

  return (
    <>
      <Container gap="0" data-testid="search-all-results-scrollable-container">
        {searchSuggestions.map(mapSuggestion)}{' '}
        {isLoading && <SearchLoadingSkeleton columns={1} />}
        {!isLoading && hasNextPage === true ? (
          <Flexbox justify="center" margin="12px">
            <Button variant="secondary" onClick={onShowMore}>
              {t`showMore`}
            </Button>
          </Flexbox>
        ) : undefined}
      </Container>
    </>
  );
};

const Container = styled(Stack)`
  max-height: 475px;
  padding-right: 8px;
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;
`;
