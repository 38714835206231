import { css, styled } from 'goober';

import { useVisibleTableData } from '../helpers/use-visible-data';
import type { ITable } from '../table.types';

import { DraggableRow } from './draggable-row';
import { DraggableRowLayer } from './draggable-row-layer';
import { DraggableTableHeaderCell } from './draggable-table-header-cell';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { FetchNextPageOptions } from '@tanstack/react-query';
import { useCallback } from 'react';
import { theme } from '@/theme/setupTheme';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';

interface DraggableTableProps<T, S> extends ITable<T> {
  hasMore: boolean;
  onLoadMore?: (options?: FetchNextPageOptions) => Promise<unknown>;
  data: S[];
}

export const DraggableTable = <T, S>({
  header,
  rows,
  sort,
  onLoadMore,
  hasMore,
  data,
}: DraggableTableProps<T, S>) => {
  const { visibleColumns, visibleRows } = useVisibleTableData({ rows, header });

  const handleLoadMore = useCallback(async () => {
    if (!hasMore || !onLoadMore) {
      return;
    }

    await onLoadMore();
  }, [hasMore, onLoadMore]);

  if (!rows.length) {
    return null;
  }

  return (
    <Container>
      <DraggableRowLayer />
      <InfiniteScroll
        dataLength={data.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={null}
        height={`calc(100vh - ${TOPBAR_HEIGHT} - 155px)`}
        className={ScrollClassName}
      >
        <Table>
          <thead>
            <tr>
              {visibleColumns.map(cell => (
                <DraggableTableHeaderCell<T>
                  key={cell.columnKey}
                  cell={cell}
                  sort={sort}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {visibleRows.map(row => (
              <DraggableRow key={row.id} row={row} />
            ))}
          </tbody>
        </Table>
      </InfiniteScroll>
    </Container>
  );
};

const Container = styled('div')`
  height: 100%;

  margin: 0px -32px;
  padding: 0px 32px;
`;

const Table = styled('table')`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 12px;
`;

const ScrollClassName = css`
  ${theme.mixins.hideScrollbar}
`;
