import type { SavvyCrunchbaseOrganizationResponse } from '@/api/v4/crunchbase.api';
import { useCompanyData } from './use-company-data.query';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';
import { getAbbreviateNumber } from '@/helpers/format-number';

export const CompanyDataCell = ({ crunchbaseId }: { crunchbaseId: string }) => {
  const { data, isLoading } = useCompanyData(crunchbaseId);

  if (isLoading) return <span>Loading...</span>;
  if (!data) return <span>--</span>;

  return <span>{data.fundingTotal || '--'}</span>;
};

interface CompanyDataRendererProps {
  crunchbaseId: string;
  field: keyof SavvyCrunchbaseOrganizationResponse;
  fallback?: React.ReactNode;
}

/**
 * Extracts year from date string
 */
export const formatYearOnly = (dateString: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  try {
    if (isNaN(date.getTime())) return '';
    return date.getFullYear().toString();
  } catch (e) {
    return '';
  }
};

export const CompanyDataRenderer: React.FC<CompanyDataRendererProps> = ({
  crunchbaseId,
  field,
  fallback = '--',
}) => {
  const { data, isLoading } = useCompanyData(crunchbaseId);

  if (isLoading) return <Skeleton width="125px" height="12px" />;
  if (!data || data[field] === undefined || data[field] === null)
    return <CompanyDataRow>{fallback}</CompanyDataRow>;

  switch (field) {
    case 'fundingTotal':
      return (
        <CurrencyValue>
          ${getAbbreviateNumber(data[field] as unknown as string)}
        </CurrencyValue>
      );

    case 'lastFundingDate':
      return (
        <CompanyDataRow>
          {formatYearOnly(data[field] as unknown as string)}
        </CompanyDataRow>
      );

    case 'lastFundingType':
      return <CompanyDataRow>{data[field] ?? '--'}</CompanyDataRow>;

    default:
      return (
        <CompanyDataRow>
          {typeof data[field] === 'object'
            ? JSON.stringify(data[field] ?? {}) || '--'
            : String(data[field] ?? '--')}
        </CompanyDataRow>
      );
  }
};

export const CompanyDataRow = styled('span')`
  color: ${({ theme }) => theme.colors.gray.c12};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: '12px';
`;

export const CurrencyValue = styled('span')`
  color: ${({ theme }) => theme.colors.gray.c12};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: '12px';
`;
