import { type ExploreResult } from './explore.state';

export const isDescriptionNotFounded = (description: string | null) =>
  description?.includes('not available');

const sortByRelevancy = (results: ExploreResult[]) => {
  return results.sort((a, b) => a.resultNumber - b.resultNumber);
};

export const sortByShortDescriptionAndRelevancy = (
  results: ExploreResult[],
) => {
  const withNoDescription = results.filter(
    result =>
      !result.shortDescription ||
      isDescriptionNotFounded(result.shortDescription),
  );

  const withDescription = results.filter(
    result =>
      result.shortDescription &&
      !isDescriptionNotFounded(result?.shortDescription),
  );

  return [
    ...sortByRelevancy([...withDescription]),
    ...sortByRelevancy([...withNoDescription]),
  ];
};
