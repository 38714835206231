import { useSearchParams } from 'react-router-dom';

export const useStageParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stageParam = searchParams.get('stage');

  const deleteStageParam = () => searchParams.delete('stage');

  const setStageParam = (stageId: string) =>
    setSearchParams(prev => ({ ...prev, stage: stageId }));

  return { stageParam, deleteStageParam, setStageParam };
};
