import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { OrganizationSearchInitial } from '@/components/organization-search-results/organization-search-initial';
import { OrganizationSearchResults } from '@/components/organization-search-results/organization-search-results';
import { OrganizationSearchResultsEmpty } from '@/components/organization-search-results/organization-search-results-empty';
import type { CreateCompanyTableRef } from '@/features/companies/create-company-table';
import { CreateCompanyTable } from '@/features/companies/create-company-table';
import { useCreateProjectListings } from '@/features/projects/project-listings/use-create-project-listings.mutation';
import { useProjectListings } from '@/features/projects/project-listings/use-project-listings.query';
import { useDebounce } from '@/hooks/use-debounce';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Modal } from '@/ui/modal/modal';
import { notify } from '@/ui/snackbar/notify';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { companiesSearchPhraseState } from '../../companies/overview/company.state';
import {
  organizationSearchResultsAddedCompaniesState,
  organizationSearchResultsCompaniesBusyState,
} from '../../companies/search-results/organization-search-results.state';
import { useAddCompanyToProject } from '../../companies/search-results/use-add-company-to-project';
import { useOrganizationsResults } from '../../companies/search-results/use-organizations-results';
import type { CreatedCompany } from '@/api/v4/organization.api';

export function AddCompanyModal() {
  const { t } = useTranslation('projects');
  const [modalConfig, setModalConfig] = useRecoilState(modalState);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
  const [showNewCompanyRow, setShowNewCompanyRow] = useState(false);
  const [showCreateProfileButton, setShowCreateProfileButton] = useState(true);
  const ref = useRef<HTMLInputElement>(null);
  const [companiesSearchPhrase, setCompaniesSearchPhrase] = useRecoilState(
    companiesSearchPhraseState,
  );
  const createNewFormRef = useRef<CreateCompanyTableRef>(null);

  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );
  const setBusyCompanies = useSetRecoilState(
    organizationSearchResultsCompaniesBusyState,
  );
  const { createProjectListing } = useCreateProjectListings();

  const projectId =
    modalConfig?.state === 'addCompany' ? modalConfig?.projectId : null;
  const onSuccess =
    modalConfig?.state === 'addCompany' ? modalConfig?.onSuccess : undefined;

  const { projectListings } = useProjectListings({ projectId });
  const alreadyInListing = (id: string) =>
    projectListings.some(listing => listing.organization.id === id) || false;

  const {
    savvyCompaniesResults,
    crunchbaseCompaniesResults,
    isCrunchbaseCompaniesResultsLoading,
    isSavvyCompaniesResultsLoading,
  } = useOrganizationsResults({
    checkIsAdded: alreadyInListing,
  });

  const addCompanyToProject = useAddCompanyToProject(projectId, onSuccess);
  const isInitialView = companiesSearchPhrase.trim().length === 0;
  const isEmptyView =
    companiesSearchPhrase.length > 0 &&
    savvyCompaniesResults?.length === 0 &&
    crunchbaseCompaniesResults?.length === 0 &&
    !isSavvyCompaniesResultsLoading &&
    !isCrunchbaseCompaniesResultsLoading;

  const debounceCompanySearchPhrase = useDebounce((searchPhrase: string) => {
    setCompaniesSearchPhrase(searchPhrase);
    if (searchPhrase && showNewCompanyRow) setShowNewCompanyRow(false);
  }, 1000);

  useEffect(() => {
    debounceCompanySearchPhrase(debouncedSearchPhrase);
  }, [debounceCompanySearchPhrase, debouncedSearchPhrase]);

  useEffect(() => {
    setDebouncedSearchPhrase('');
    setCompaniesSearchPhrase('');
    setShowNewCompanyRow(false);
    setShowCreateProfileButton(true);
  }, []);

  useEffect(() => {
    setAddedCompanies([]);
    setBusyCompanies([]);
  }, [companiesSearchPhrase, setAddedCompanies, setBusyCompanies]);

  const handleCloseModal = useCallback(() => {
    setModalConfig(null);
    if (addedCompanies.length > 0)
      notify({
        message: t('addCompany.wereAdded', {
          companiesCount: addedCompanies.length,
        }),
        withIcon: true,
      });
  }, [addedCompanies, setModalConfig, t]);

  const openNewCompanyForm = useCallback(() => {
    createNewFormRef.current?.reset();
    setDebouncedSearchPhrase('');
    setShowNewCompanyRow(true);
    setShowCreateProfileButton(false);
  }, []);

  const onCompanyCreated = useCallback(
    async (company: CreatedCompany) => {
      if (!projectId) return;

      await createProjectListing(projectId, {
        companyIds: [company.id],
      });

      setShowCreateProfileButton(true);
    },
    [createProjectListing, projectId],
  );

  if (modalConfig?.state !== 'addCompany') return null;

  return (
    <Modal
      onClose={handleCloseModal}
      CustomHeaderComponent={SubHeaderBold}
      header={t`addCompany.addToProject`}
    >
      <ContentContainer
        name="add-company-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="987px"
        height="575px"
      >
        <Flexbox name="modal-input" direction="column" gap="18.5px">
          <Input
            value={debouncedSearchPhrase}
            placeholder={t`projects.search`}
            onChange={event => setDebouncedSearchPhrase(event.target.value)}
            autoFocus
            ref={ref}
          />
        </Flexbox>

        <Content
          name="projects-list"
          fullWidth
          justify="flex-start"
          grow={1}
          data-testid="search-companies-results"
        >
          {showNewCompanyRow ? (
            <CreateCompanyTable
              ref={createNewFormRef}
              onCompanyCreated={onCompanyCreated}
            />
          ) : isInitialView ? (
            <OrganizationSearchInitial
              addCompanyText={t`addCompany.searchAndCreateSnapshot`}
            />
          ) : isEmptyView ? (
            <OrganizationSearchResultsEmpty
              addCompanyText={t`addCompany.createOwnProfile`}
              noResultText={t`addCompany.couldNotFindCompany`}
              onAddCustomCompanyClick={openNewCompanyForm}
            />
          ) : (
            <OrganizationSearchResults
              buttonAddText={t`addCompany.add`}
              buttonAddedText={t`addCompany.added`}
              savvyResultsHeader={t`addCompany.addFromSavvy`}
              crunchbaseResultsHeader={t`addCompany.addFromCrunchbase`}
              onAddSavvyCompanyClick={addCompanyToProject}
              onAddCrunchbaseCompanyClick={addCompanyToProject}
              savvyCompaniesData={savvyCompaniesResults}
              isSavvyCompaniesLoading={isSavvyCompaniesResultsLoading}
              crunchbaseCompaniesData={crunchbaseCompaniesResults}
              isCrunchbaseCompaniesLoading={isCrunchbaseCompaniesResultsLoading}
            />
          )}
        </Content>

        <Flexbox
          name="modal-footer"
          justify={'space-between'}
          alignItems="flex-end"
        >
          <CompaniesCounter>
            {t('projects.company', { count: addedCompanies.length })}{' '}
            {t`addCompany.companyAdded`}
          </CompaniesCounter>

          {showCreateProfileButton && (
            <Button
              startIcon="Plus"
              variant="white"
              color={colors.blue.primaryA}
              onClick={openNewCompanyForm}
            >
              {t`addCompany.createOwnProfile`}
            </Button>
          )}
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled(Flexbox)<{ width: string }>`
  width: ${({ width }) => width};
  margin-top: 16px;
`;

const CompaniesCounter = styled('div')`
  &::first-letter {
    ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
    vertical-align: baseline;
  }
`;

const Content = styled(Flexbox)`
  ${({ theme }) => theme.mixins.scrollbar}
  overflow: auto;
  & > div {
    width: 100%;
    ${({ theme }) => theme.mixins.scrollbar}
  }
`;
