import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { openNewTab } from '@/helpers/open-new-tab';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { useCompanies } from '../../companies/hooks/use-companies.query';

import { useGenAIRequestResultMutation } from './use-genai-request-result-mutation';
import { useGenAIRequestResultOrganizationMutation } from './use-genai-request-result-organization-mutation';
import type {
  CreatedCompany,
  CreatedCompanyFromCB,
} from '@/api/v4/organization.api';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { type ExploreResult } from '../explore.state';

export const ExploreCreateCompanyActionButton = ({
  isCreated = false,
  isCreating = false,
  onClick,
  requestId,
  suggestion,
}: {
  isCreated?: boolean;
  isCreating?: boolean;
  onClick: (
    item: ExploreResult,
  ) => Promise<CreatedCompany | CreatedCompanyFromCB | undefined>;
  requestId: string;
  suggestion: ExploreResult;
}) => {
  const { t } = useTranslation('explore');
  const setModalState = useSetRecoilState(modalState);
  const { mutateAsync: updateGenAIResultStatus } =
    useGenAIRequestResultMutation();
  const { mutateAsync: updateGenAIResultOrganization } =
    useGenAIRequestResultOrganizationMutation();
  const navigateTo = useNavigateTo();

  const { refetch: refreshCompanies } = useCompanies();

  const [addedCompanyId, setAddedCompanyId] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const isCompanyCreated = !!addedCompanyId || isCreated;

  const onAddCompanyDialogClose = async (createdCompanies: string[]) => {
    if (createdCompanies.length > 0) {
      //currentlty this dialog allows multiple creation. we will use the first item as the original company to associate with the suggestion
      await updateGenAIResultOrganization({
        params: { requestId, resultNumber: suggestion.resultNumber.toString() },
        body: { newOrganizationId: createdCompanies[0] },
      });
      await updateGenAIResultStatus({
        params: { requestId, resultNumber: suggestion.resultNumber.toString() },
        body: { newStatus: 1 },
      });
      setAddedCompanyId(createdCompanies[0]);
      await refreshCompanies();
    }
  };

  const getStartIcon = useCallback(() => {
    if (isCreating) return 'Loader';
    if (!isCompanyCreated) return 'Plus';
    return undefined;
  }, [isCompanyCreated, isCreating]);

  const handleClick = async () => {
    if (isCompanyCreated && organizationId) {
      return navigateTo.company({ companyId: organizationId });
    }

    if (isCompanyCreated) return;

    if (suggestion.organizationId) {
      const savvyPath = paths.company({
        companyId: suggestion.organizationId,
      });
      return openNewTab(savvyPath);
    } else if (!suggestion.crunchbaseId) {
      return setModalState({
        state: 'createNewCompany',
        defaultSearchPhrase: suggestion.name,
        defaultWebsite: suggestion.website,
        navigateToPageOnCreated: false,
        onClose: onAddCompanyDialogClose,
      });
    }

    const organization = await onClick(suggestion);
    if (!organization) return;

    setOrganizationId(organization.id);
  };

  return (
    <Tooltip visible={!suggestion.crunchbaseId} content={t`createUnavailable`}>
      <CreateButton
        startIcon={getStartIcon()}
        endIcon={isCompanyCreated ? 'VectorUp' : undefined}
        onClick={handleClick}
        isCreated={isCompanyCreated}
        color={isCompanyCreated ? colors.accent.green.c3 : undefined}
      >
        {isCompanyCreated
          ? t`created`
          : suggestion.crunchbaseId
          ? t`create`
          : t`findAndCreate`}
      </CreateButton>
    </Tooltip>
  );
};

export const CreateButton = styled(Button)<{ isCreated?: boolean }>`
  ${({ isCreated, theme }) =>
    isCreated &&
    `background-color: ${theme.colors.accent.green.c1};
    &:hover {
      color: ${theme.colors.accent.green.c3};
      background-color: ${theme.colors.accent.green.c4};
    }`}
`;
