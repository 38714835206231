import type { SearchSuggestionQuery } from '@/api/v4/search.api';
import { atom } from 'recoil';

export type SearchResultsFilter =
  | 'all'
  | 'project'
  | 'company'
  | 'team'
  | 'tag';

export const searchResultsFilters: SearchResultsFilter[] = [
  'all',
  'company',
  'project',
  'team',
  'tag',
];

export const searchResultsFilterState = atom<SearchResultsFilter>({
  key: 'searchResultsFilter',
  default: 'all',
});

export const searchQueryState = atom<SearchSuggestionQuery>({
  key: 'searchQueryState',
  default: {
    search: '',
    page: 1,
    pageSize: 12,
    entities: ['all'],
  },
});
