import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { fetchProjectTypes } from '@/api/v4/project-types.api';

export const useProjectTypes = () => {
  return useQuery([QueryKey.ProjectTypes], fetchProjectTypes, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
