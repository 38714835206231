import {
  fetchCompanyCrunchbaseDataByCrunchbaseId,
  type SavvyCrunchbaseOrganizationResponse,
} from '@/api/v4/crunchbase.api';
import { QueryKey } from '@/config/query-client';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { atom } from 'recoil';

// Define a cache structure for company data
interface CompanyDataCache {
  [crunchbaseId: string]: {
    data: SavvyCrunchbaseOrganizationResponse;
    timestamp: number;
    status: 'loading' | 'success' | 'error';
    error?: Error;
  };
}

export const companyDataCacheState = atom<CompanyDataCache>({
  key: 'companyDataCacheState',
  default: {},
});

export const companyDataRequestsInProgressState = atom<Set<string>>({
  key: 'companyDataRequestsInProgressState',
  default: new Set(),
});

export const useCompanyData = (
  crunchbaseId?: string,
  options?: Omit<
    UseQueryOptions<
      SavvyCrunchbaseOrganizationResponse,
      Error,
      SavvyCrunchbaseOrganizationResponse,
      readonly string[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<
    SavvyCrunchbaseOrganizationResponse,
    Error,
    SavvyCrunchbaseOrganizationResponse,
    readonly string[]
  >(
    [QueryKey.CompanySavvyCrunchbase, crunchbaseId ?? ''],
    () => fetchCompanyCrunchbaseDataByCrunchbaseId(crunchbaseId!),
    {
      enabled: !!crunchbaseId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      ...options,
    },
  );
};
