import { fetchCompanyCrunchbaseDataByCrunchbaseId } from '@/api/v4/crunchbase.api';
import { QueryKey } from '@/config/query-client';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Hook to prefetch company data
 * @returns A function that prefetches data for the given IDs
 */
export const usePrefetchCompanyData = () => {
  const queryClient = useQueryClient();

  /**
   * Prefetches data for multiple company IDs
   * @param crunchbaseIds - Array of Crunchbase IDs to prefetch
   */
  const prefetchCompanies = async (crunchbaseIds: string[]) => {
    const uniqueIds = [...new Set(crunchbaseIds)].filter(id => !!id);

    await Promise.all(
      uniqueIds.map(async id => {
        const queryKey = [QueryKey.CompanySavvyCrunchbase, id];
        // Only prefetch if not already in cache or if stale
        const existingData = queryClient.getQueryData(queryKey);
        if (!existingData) {
          try {
            await queryClient.prefetchQuery({
              queryKey: queryKey,
              queryFn: () => fetchCompanyCrunchbaseDataByCrunchbaseId(id),
              staleTime: 5 * 60 * 1000,
            });
          } catch (error) {
            console.error(`Error prefetching data for ${id}:`, error);
            // Errors are captured but don't stop other prefetches
          }
        }
      }),
    );
  };

  return prefetchCompanies;
};
