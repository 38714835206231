import { useFormContext } from 'react-hook-form';

import { isDefaultOption } from '@/helpers/other';

import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import type { ProjectHeaderForm } from './use-project-header-form';
import { FormSelect } from '@/features/explore/ui/form-select';
import { colors } from '@/theme/colors';
import { styled } from 'goober';
import { fetchAllUsers } from '@/api/v4/user.api';
import { APP_USER_ROLES } from '@/types/api';

export const OwnerSelect = () => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<ProjectHeaderForm>();

  const ownerDefaultValue = defaultValues?.owner ?? null;

  const userOptions: AsyncSelectProps['setOptions'] = async (
    search: string,
  ) => {
    const options = await fetchAllUsers({
      search,
      statuses: ['active'],
      roles: [APP_USER_ROLES.MANAGER, APP_USER_ROLES.ADMIN],
    });

    return options
      .filter(user => user.role.name !== APP_USER_ROLES.GUEST)
      .map(user => ({
        value: String(user.id),
        label: `${user.firstName} ${user.lastName}`,
      }));
  };

  return (
    <SelectWrapper>
      <FormSelect
        dataTestId="project-owner-select"
        autoFocus
        defaultValue={ownerDefaultValue}
        setOptions={userOptions}
        onChange={(newValue: unknown, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setValue('owner', null, {
              shouldDirty: true,
            });
          }

          if (isDefaultOption(newValue)) {
            setValue('owner', newValue, {
              shouldDirty: true,
            });
          }
        }}
        backgroundColor="white"
        variant="small"
        dropdownIndicatorColor={colors.basics.black}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div')`
  width: 110px;
`;
