import { exportToCSV } from '@/api/v4/projects.api';
import { useMutation } from '@tanstack/react-query';

export const useExportToCSV = () => {
  return useMutation(
    async ({
      projectId,
      projectName,
    }: {
      projectId: string;
      projectName: string;
    }) => await exportToCSV(projectId, projectName),
  );
};
