import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash-es';
import { useRecoilState, useRecoilValue } from 'recoil';

import type {
  MeetingCreatePayload,
  MeetingEditPayload,
} from '@/api/v4/meetings.api';
import { projectIdState } from '@/features/projects/project.state';
import type { IAttendeeOption } from '@/types';
import { useCurrentUser } from '@/user/use-current-user.query';

import { getValidationSchema } from '../get-validation-schema';
import { activeMeetingFormState } from '../meetings.state';

import { useCreateMeeting } from './use-create-meeting.mutation';
import { useEditMeeting } from './use-edit-meeting.mutation';
import { useActionPanelSearchParams } from '../../use-action-panel-search-params';
import { actionPanelState } from '../../action-panel.state';

export interface MeetingFormFields {
  title: string;
  date: string;
  relatedCompanyId?: string;
  relatedProjectId?: string | null;
  relatedTeamId?: string | null;
  attendees: IAttendeeOption[];
  summary?: string;
  mentionedUsers?: number[];
}

export const useMeetingForm = ({
  meetingId,
  defaultValues,
  isCompanyRequired = false,
}: {
  meetingId?: string;
  isCompanyRequired?: boolean;
  defaultValues: MeetingFormFields | null;
}) => {
  const methods = useForm<MeetingFormFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: zodResolver(getValidationSchema(isCompanyRequired)),
    defaultValues: defaultValues ?? {
      title: '',
      date: '',
      relatedCompanyId: undefined,
      relatedProjectId: undefined,
      relatedTeamId: undefined,
      attendees: [],
      summary: '',
      mentionedUsers: [],
    },
  });
  const {
    handleSubmit,
    formState: { dirtyFields, isDirty },
  } = methods;
  const [activeMeetingForm, setActiveMeetingForm] = useRecoilState(
    activeMeetingFormState,
  );
  const { data: user } = useCurrentUser();
  const { setMeetingParam } = useActionPanelSearchParams();
  const projectId = useRecoilValue(projectIdState);
  const { createMeeting } = useCreateMeeting();
  const { editMeeting } = useEditMeeting();

  const actionPanelStateValue = useRecoilValue(actionPanelState);

  useEffect(() => {
    setActiveMeetingForm({
      ...activeMeetingForm,
      isDirty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const entity = useMemo(() => {
    switch (actionPanelStateValue?.context) {
      case 'company':
        return 'organization';
      case 'project':
        return 'project';
      case 'team':
        return 'team';
    }

    return null;
  }, [actionPanelStateValue?.context]);

  const submitEditedMeeting = async (values: MeetingFormFields) => {
    if (!meetingId || !entity) {
      return;
    }

    const payload: MeetingEditPayload = {
      id: meetingId,
      ...(dirtyFields.title &&
        values.title && {
          title: values.title,
        }),

      ...(dirtyFields.date &&
        values.date && {
          meetingDate: values.date,
        }),

      ...(dirtyFields.relatedCompanyId && {
        organizationId: values.relatedCompanyId,
      }),

      ...(dirtyFields.relatedProjectId && {
        projectId: values.relatedProjectId,
      }),

      ...(dirtyFields.relatedTeamId && {
        teamId: values.relatedTeamId,
      }),

      ...(dirtyFields.summary && {
        summary: values.summary,
        mentionedUsers: values.mentionedUsers,
      }),

      attendees: mapAttendeesOptionsToPayload(values.attendees),
      entity,
      projectId: dirtyFields.relatedProjectId
        ? values.relatedProjectId
        : actionPanelStateValue?.context === 'project'
        ? projectId
        : undefined,
    };

    if (!isEmpty(dirtyFields)) {
      await editMeeting(payload);

      setActiveMeetingForm({
        ...activeMeetingForm,
        id: meetingId,
      });
      setMeetingParam(meetingId);
    }
  };

  const submitNewMeeting = async (values: MeetingFormFields) => {
    if (!entity) {
      return;
    }

    const payload: MeetingCreatePayload = {
      meetingDate: values.date,
      attendees: mapAttendeesOptionsToPayload(values.attendees),
      title: !!values.title ? values.title : generateMeetingTitle(),

      ...(values.relatedCompanyId && {
        organizationId: values.relatedCompanyId,
      }),

      ...(values.relatedTeamId && {
        teamId: values.relatedTeamId,
      }),

      ...(values.relatedProjectId && {
        projectId: values.relatedProjectId,
      }),

      ...(dirtyFields.summary &&
        values.summary && {
          summary: values.summary,
          mentionedUsers: values.mentionedUsers,
        }),
      entity,
    };

    const meeting = await createMeeting(payload);

    setActiveMeetingForm({
      ...activeMeetingForm,
      id: meeting.id,
    });
    setMeetingParam(meeting.id);
  };

  const generateMeetingTitle = () =>
    `Meeting with ${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  return {
    methods,
    handleEditMeetingFormSubmit: handleSubmit(submitEditedMeeting),
    handleCreateMeetingFormSubmit: handleSubmit(submitNewMeeting),
  };
};

const mapAttendeesOptionsToPayload = (attendees: IAttendeeOption[]) =>
  attendees.map(attendee => ({
    id: attendee.value,
    type: attendee.type,
  }));
