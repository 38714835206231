import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { CompanySortOptions } from '@/api/v4/organization.api';
import type { IOption } from '@/types';
import type { ISort } from '@/ui/table/table.types';

import type { GenAiRequestHistoryResult } from '@/api/v4/genai-request-history.api';
import type { ExploreOrganizationSuggestionResult } from '@/api/v4/organization-suggestions.api';

export type ExploreResult =
  | ExploreOrganizationSuggestionResult
  | GenAiRequestHistoryResult;
import type {
  CreateOrganizationDescriptionPayload,
  CreateOrganizationSuggestionPayload,
} from '@/api/v4/organization-suggestions.api';
import { type SavvyCrunchbaseOrganizationResponse } from '@/api/v4/crunchbase.api';

const { persistAtom } = recoilPersist();

export interface ExampleCompany {
  id: string;
  name: string;
  website: string;
  logoUrl: string;
}

export const exploreFormDescriptionState = atom<string>({
  key: 'exploreFormDescriptionState',
  default: '',
  effects: [persistAtom],
});

export const exploreFormLocationsState = atom<IOption[]>({
  key: 'exploreFormLocationsState',
  default: [],
  effects: [persistAtom],
});

export const exploreFormExampleCompaniesState = atom<ExampleCompany[]>({
  key: 'exploreFormExampleCompaniesState',
  default: [],
  effects: [persistAtom],
});

export const exploreResultsSelectedCompaniesState = atom<ExploreResult[]>({
  key: 'exploreResultsSelectedCompaniesState',
  default: [],
});

export const exploreResultsCreatingCompaniesState = atom<ExploreResult[]>({
  key: 'exploreResultsCreatingCompaniesState',
  default: [],
});

export const exploreResultsCreatedCompaniesState = atom<ExploreResult[]>({
  key: 'exploreResultsCreatedCompaniesState',
  default: [],
});

export const exploreResultsSortState = atom<ISort<CompanySortOptions | null>>({
  key: 'exploreResultsSortState',
  default: {
    sort: null,
    direction: 'desc',
  },
});

export const isExploreRequestHistoryPaneOpenState = atom<boolean>({
  key: 'isExploreRequestHistoryPaneOpenState',
  default: false,
});

export const generatedShortDescriptionsState = atom<Record<string, string>>({
  key: 'generatedShortDescriptionsState',
  default: {},
});

export type AIModelType =
  | CreateOrganizationSuggestionPayload['model']
  | CreateOrganizationDescriptionPayload['model'];

export type ExploreFormState = {
  enabledExploreAccountData: CreateOrganizationSuggestionPayload['enableExploreAccountData'];
  model: AIModelType;
};

export const exploreFormState = atom<ExploreFormState>({
  key: 'exploreFormState',
  default: {
    model: 'openai',
    enabledExploreAccountData: false,
  },
});

// Define a cache structure for company data
interface CompanyDataCache {
  [crunchbaseId: string]: {
    data: SavvyCrunchbaseOrganizationResponse;
    timestamp: number;
    status: 'loading' | 'success' | 'error';
    error?: Error;
  };
}

// Atom to store all company data in a central cache
export const companyDataCacheState = atom<CompanyDataCache>({
  key: 'companyDataCacheState',
  default: {},
});

// Atom to track ongoing requests (to prevent duplicate simultaneous requests)
export const companyDataRequestsInProgressState = atom<Set<string>>({
  key: 'companyDataRequestsInProgressState',
  default: new Set(),
});
